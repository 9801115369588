<template>
    <div class="auio-container">
        <div style="display: flex;align-items: flex-start;">
            <img src="../../assets/app/icon_arrow_black_left.png" @click="goBack"
                style="width: 24px;height: auto;object-fit: cover;margin-right: 15px;cursor: pointer;">
            <div style="cursor: pointer;;" @click="currentTab = 0">
                <p :class="currentTab == 0 ? 'audio-head-tab-selected' : 'audio-head-tab'">音频</p>
                <img v-if="currentTab == 0" src="../../assets/app/icon_audio_arrow.png" class="audio-tab-arrow">
            </div>
            <div style="cursor: pointer;" @click="currentTab = 1">
                <p :class="currentTab == 1 ? 'audio-head-tab-selected' : 'audio-head-tab'">文本</p>
                <img v-if="currentTab == 1" src="../../assets/app/icon_audio_arrow.png" class="audio-tab-arrow">
            </div>
            <div style="cursor: pointer;" @click="currentTab = 2">
                <p :class="currentTab == 2 ? 'audio-head-tab-selected' : 'audio-head-tab'">问答</p>
                <img v-if="currentTab == 2" src="../../assets/app/icon_audio_arrow.png" class="audio-tab-arrow">
            </div>
        </div>

        <div v-if="currentTab == 0"
            style="padding: 0px 50px;box-sizing: border-box;width: 100%;flex: 1;display: flex;flex-direction: column;margin-top: 100px;">
            <img v-if="albumInfo.cover_pic_url" :src="albumInfo.cover_pic_url"
                style="width: 251px;height: 251px;object-fit: cover;">
            <img v-else src="../../assets/app/icon_default_album.png" style="width: 251px;height: 251px;object-fit: cover;">
            <p style="color: #5D5BB5;font-size: 18px;font-weight: bold;margin-top: 15px;">{{ songInfo.song_name }}</p>
            <div style="margin-top: 10px;">
                <p
                    style="display: inline-block;color: white;font-size: 12px;background-color: #616BAE;border-radius: 6px;padding: 4px 9px;">
                    {{ albumInfo.album_name }}</p>
            </div>
            <p style="color: #9698A2;font-size: 14px;margin-top: 10px;">{{ songInfo.song_name_abbrev }}</p>
        </div>
        <div v-if="currentTab == 0">
            <el-progress percentage="0" :show-text="false"></el-progress>
            <div style="display: flex;color: #9698A2;font-size: 14px;margin-top: 15px;">
                <p style="flex: 1;">0:00</p>
                <p>{{ song_duration }}</p>
            </div>
            <div style="display: flex;margin-top: 20px;align-items: center;width: 100%;justify-content: center;">
                <img src="../../assets/app/icon_audio_comment.png" @click="gotoComment"
                    style="margin-top: -20px;width: 30px;height: 30px;object-fit: cover;cursor: pointer;">
                <img src="../../assets/app/icon_audio_pre.png" @click="doPlayLast"
                    style="margin-top: -20px;width: 30px;height: 30px;object-fit: cover;margin-left: 30px;cursor: pointer;">
                <img src="../../assets/app/icon_audio_play.png" @click="doPlay"
                    style="margin: 0px 20px;width: 98px;height: 98px;object-fit: cover;cursor: pointer;">
                <img src="../../assets/app/icon_audio_next.png" @click="doPlayNext"
                    style="margin-top: -20px;width: 30px;height: 30px;object-fit: cover;margin-right: 30px;cursor: pointer;">
                <img v-if="songInfo.favorite_status == false" src="../../assets/app/icon_audio_fav.png" @click="favSong"
                    style="margin-top: -20px;width: 30px;height: 30px;object-fit: cover;cursor: pointer;">
                <img v-else @click="favSong" src="../../assets/app/icon_audio_fav_selected.png"
                    style="margin-top: -20px;width: 30px;height: 30px;object-fit: cover;cursor: pointer;">
            </div>
        </div>

        <div v-if="currentTab == 1" style="width: 100%;padding: 20px 10px;box-sizing: border-box;flex: 1;overflow-y: auto;">
            <p style="color: #333333;font-size: 24px;font-weight: bold;">{{ songInfo.song_name }}</p>
            <p style="color: #6E707D;font-size: 14px;margin-top: 10px;">{{ songInfo.song_name_abbrev }}</p>
            <p style="margin-top: 20px;color: #454651;font-size: 16px;line-height: 28px;" v-if="songInfo.song_lyrics_web"
                v-html="songInfo.song_lyrics_web"></p>
            <div v-else style="margin-top: 20px;color: #454651;font-size: 16px;line-height: 28px;">
                <div v-for="(content, index) in songInfo.song_lyrics_paragraph" :key="index" style="margin-bottom: 15px;">
                    <p style="text-indent: 2rem;"> {{ content }}</p>
                </div>
            </div>
        </div>

        <div v-if="currentTab == 2" style="width: 100%;padding: 20px 10px;box-sizing: border-box;flex: 1;overflow-y: auto;">
            <p style="color: #333333;font-size: 24px;font-weight: bold;text-align: center;">{{ songInfo.song_name }}</p>
            <div style="display: flex;align-items: center;margin-top: 15px;">
                <img src="../../assets/app/icon_answer.png" style="width: 36px;height: 36px;object-fit: cover;">
                <p style="color: #5F6AAF;font-size: 14px;margin-left: 5px;">课后问题</p>
                <div style="margin-left: 15px">
                    <img v-if="hasAnswered == true" src="../../assets/app/icon_child_answer.png"
                        style="width: 16px;height: 14px;object-fit: cover;">
                    <img v-else src="../../assets/app/icon_hint_child_answer.png"
                        style=";width: 16px;height: 14px;object-fit: cover;">
                </div>
                <p style="color: #ffaf49;font-size: 10px;margin-left: 5px;"> {{ hasAnswered == true ? '以下为孩子的答题情况' :
                    '您的孩子还未答题' }}</p>
            </div>
            <p style="color: #333333;font-size:16px;margin-top:20px;margin-bottom: 20px;">{{ quizQuestion }}</p>

            <div v-for="(option, index) in quizOptions" :key="index" class="quiz-item" :style="customOptionStyle(option)">
                <p class="option" :style="customOptionStyle(option)">
                    {{ getOptionIndex(index) }}
                </p>
                <p class="content" :style="customOptionStyle(option)">
                    {{ "&nbsp&nbsp|&nbsp" + option.value }}
                </p>
                <div v-if="option.reviewOption">
                    <img src="../../assets/app/answer_yes.png" class="result" v-if="option.styleType == 'success'" />
                    <img src="../../assets/app/answer_no.png" class="result" v-else-if="option.styleType == 'failure'" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getAlbum,
    getSong,
    favoriteSong
} from '../../api/app'
import {
    getAppUserId
} from '../../utils/store'
import { Loading } from "element-ui";
import Cookies from 'js-cookie'

export default ({
    name: 'index',
    data() {
        return {
            user_id: getAppUserId(),
            songList: [],
            songIndex: 0,
            albumInfo: {},
            songInfo: {

            },
            from: 'album',
            currentTab: 0,
            song_duration: '',
            hasAnswered: false,
            quizOptions: [],
            quizQuestion: ''
        }
    },
    mounted() {
        this.songList = this.$songList
        let tempIndex = Cookies.get('index')
        if (tempIndex >= 0) {
            Cookies.set('index', -1)
            this.songIndex = tempIndex
        } else {
            this.songIndex = this.$route.query.index
        }
        this.requestSongInfo()
    },
    methods: {

        doPlayLast() {
            let index = this.songIndex
            index--
            if (index < 0) {
                this.$message.warning('已经是第一个音频了')
                return
            }
            this.songIndex = index
            this.requestSongInfo()
        },
        doPlayNext() {
            let index = this.songIndex
            index++
            if (index > (this.songList.length - 1)) {
                this.$message.warning('已经是最后一个音频了')
                return
            }
            this.songIndex = index
            this.requestSongInfo()
        },

        doPlay() {
            this.$message.success('请小朋友前往儿童版进行收听和答题')
        },

        gotoComment() {
            Cookies.set('index', this.songIndex)
            this.$router.push({
                path: '/app-songComment',
                query: {
                    song_id: this.songInfo._id.$id
                }
            })
        },
        favSong() {
            favoriteSong(this.songInfo._id.$id).then((res) => {
                this.refreshSongInfo()
            })
        },

        goBack() {
            this.$router.go(-1)
        },

        refreshSongInfo() {
            getSong(this.songInfo._id.$id, this.from).then((res) => {
                this.songInfo = res.data.data
            })
        },

        async requestSongInfo() {

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            this.hasAnswered = false
            this.quizOptions = []

            let song = this.songList[this.songIndex]

            let songResult = await getSong(song._id.$id, this.from)
            this.songInfo = songResult.data.data

            let firstQuizContent = this.songInfo.song_quiz_contents[0]

            this.quizQuestion = firstQuizContent.quiz_question
            let quiz_options = firstQuizContent.quiz_options

            let hasRecord = false
            let user_answer = -1
            let correct = false
            if (firstQuizContent.user_record) {
                hasRecord = true
                user_answer = firstQuizContent.user_record.answer
            }
            this.hasAnswered = hasRecord
            let question_answer = firstQuizContent.quiz_answer
            if (hasRecord) {
                if (user_answer == question_answer) {
                    correct = true
                }
            }
            for (let i = 0; i < quiz_options.length; i++) {
                let obj = {
                    selected: false,
                    reviewOption: hasRecord,
                    value: quiz_options[i],
                }
                if (hasRecord) {
                    let styleType = this.getOptionStyleType(
                        i + 1,
                        user_answer,
                        question_answer,
                        correct
                    );
                    obj["styleType"] = styleType;
                }
                this.quizOptions.push(obj);
            }
            let album_id = song.album_id
            let albumResult = await getAlbum(album_id, this.user_id)
            this.albumInfo = albumResult.data.data
            this.getAudioDuration()
            loadingInstance.close()
        },
        async getAudioDuration() {
            const audio = document.createElement('audio')
            var url = this.songInfo.song_audio_address
            audio.src = url
            audio.id = 'checktimevideo'
            audio.style.display = 'none'
            document.body.appendChild(audio)
            this.getTime(audio)
            return ''
        },
        getTime(video) {
            let that = this
            const promise = new Promise(resolve => {
                video.addEventListener('canplaythrough', e => {
                    let duration = e.target.duration
                    that.song_duration = this.formatSeconds(duration)
                    console.log('that.song_duration :' + that.song_duration)
                    resolve(e.target.duration)
                })
            })
            return promise
        },
        formatSeconds(seconds) {
            let min = 0
            let sec = seconds
            if (seconds >= 60) {
                min = parseInt(seconds / 60)
                sec = parseInt(seconds - min * 60)
            }
            let str_min = min
            let str_sec = sec
            if (sec < 10) {
                str_sec = '0' + sec
            }
            return str_min + ':' + str_sec
        },
        getOptionIndex(pos) {
            let index = parseInt(pos)
            switch (index) {
                case 0:
                    return "A";
                case 1:
                    return "B";
                case 2:
                    return "C";
                case 3:
                    return "D";
                case 4:
                    return "E";
                case 5:
                    return "F";
            }
        },
        getOptionStyleType(index, answer, question_answer, correct) {
            var styleType = "normal";
            if (correct) {
                if (index == answer) {
                    styleType = "success";
                }
            } else {
                if (index == question_answer) {
                    styleType = "success";
                } else if (index == answer) {
                    styleType = "failure";
                }
            }
            return styleType;
        },
        customOptionStyle(option) {
            let style = {};
            if (option.reviewOption) {
                let styleType = option.styleType;
                if (styleType == "normal") {
                    style.background = "#FFFFFF";
                    style.color = "#333333";
                    style.borderColor = "#F0F0F0";
                    style.borderWidth = "1px";
                } else if (styleType == "failure") {
                    style.background = "#FFF4F4";
                    style.color = "#EA5350";
                    style.borderColor = "#EA5350";
                    style.borderWidth = "1px";
                } else if (styleType == "success") {
                    style.background = "#F3FFF2";
                    style.borderColor = "#0DAF00";
                    style.color = "#0DAF00";
                    style.borderWidth = "1px";
                }
            } else {
                if (option.selected) {
                    style.background = "#F2F5FF";
                    style.borderColor = "#5D5BB5";
                    style.color = "#5D5BB5";
                    style.borderWidth = "1px";
                } else {
                    style.background = "#FFFFFF";
                    style.color = "#333333";
                    style.borderColor = "#F0F0F0";
                    style.borderWidth = "1px";
                }
            }
            return style;
        },
    }
})
</script>

<style lang="less" scoped>
.quiz-item {
    width: 100%;
    border: 1px solid #e6e7eb;
    border-radius: 27px;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .option {
        color: #444C7D;
        font-size: 18px;
    }

    .content {
        flex: 1;
        padding: 0px;
        color: #454651;
        font-size: 14px;
    }

    .result {
        width: 14px;
        height: 14px;
        object-fit: cover;
    }
}

.auio-container {
    width: 100vw;
    height: 100vh;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.audio-tab-arrow {
    width: 20px;
    height: auto;
    object-fit: cover;
    margin-left: 8px;
}

.audio-head-tab {
    margin-right: 20px;
    color: #9698A2;
    font-size: 18px;
}

.audio-head-tab-selected {
    .audio-head-tab();
    color: #333333;
}
</style>